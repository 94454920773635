import {ConfigService} from "../../resources/services/ConfigService";
import * as environment from "../../../config/environment.json";
import {FhirService} from "../../resources/services/FhirService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {UserService} from "../../resources/services/UserService";
import {ModalQuestionnaireDefault} from "../../resources/elements/modal-questionnaire-default";
// import {QuestionnaireDefault} from "../../views/patient/questionnaire-default"
import {inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {Router} from "aurelia-router";

@inject(UserService, DialogService, Router)
export class BeditRoot {
    router: Router
    wardId = null
    iframeEnabled
    iframeUrl
    iframeListener
    iframeOrigin
    iframeContainer
    config
    userService
    dialogService
    dialogIsOpen = false

    constructor(userService: UserService, dialogService: DialogService, router: Router) {
        this.router = router;
        this.userService = userService;
        this.dialogService = dialogService;
        this.iframeListener = (event) => {
            if (event.origin === this.iframeOrigin) {
                switch (event.data.name) {
                    case 'getFormSettings': {
                        this.iframeContainer.contentWindow.postMessage({
                            name: 'getFormSettings',
                            data: JSON.stringify(ConfigService.FormSettings)
                        }, this.iframeUrl);
                        break;
                    }
                    case 'getFhirVersion': {
                        this.iframeContainer.contentWindow.postMessage({
                            name: 'getFhirVersion',
                            data: FhirService.FhirVersion
                        }, this.iframeUrl);
                        break;
                    }
                    case 'openIndirektePflege': {
                        this.dialogIsOpen = true
                        this.dialogService.open({
                            viewModel: ModalQuestionnaireDefault,
                            // viewModel: QuestionnaireDefault,
                            model: {
                                id: '97E367E7-F5C6-4903-9067-F5BE9D63F7A6',
                                name: 'CareITIndirektePflege'
                            }
                        }).whenClosed(() => {
                            this.dialogIsOpen = false
                        });
                        break;
                    }
                    case 'goBack': {
                        this.router.navigateBack()
                        break;
                    }
                    case 'openEncounter': {
                        this.router.navigateToRoute('encounter', {id: event.data.data})
                        break;
                    }
                }
            }
        }
    }

    activate(params) {
        if (params['id']) {
            this.wardId = params['id']
        }

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Ward);
        this.iframeEnabled = this.config.settings && this.config.settings.iframe && this.config.settings.iframe.enabled
    }

    attached() {
        if (this.iframeEnabled) {
            window.addEventListener("message", this.iframeListener);

            const loginData = sessionStorage.getItem(environment.sessionName);

            const query = {
                login: loginData,
                server: FhirService.Endpoint,
                wardId: this.wardId,
                practitionerId: '',
            };

            if (this.userService.practitioner) {
                query.practitionerId = this.userService.practitioner.id;
            }

            this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
                return `${key}=${encodeURIComponent(query[key])}`
            }).join('&')
            this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
        }

        RuntimeInfo.TogglePatientListLG(true);
    }

    detached() {
        if (this.iframeEnabled) {
            window.removeEventListener("message", this.iframeListener)
        }

        RuntimeInfo.TogglePatientListLG(false);
    }

    canDeactivate() {
        if (this.dialogIsOpen) {
            this.dialogService.closeAll()
            return false
        }

        return true
    }
}
